import React, { Fragment, ReactNode, useState } from 'react'
import { Tab, Tabs } from '@mui/material'

import Scene1 from '../../assets/videos/kouraiScene1.mp4'
import Scene2 from '../../assets/videos/kouraiScene2.mp4'
import Scene3 from '../../assets/videos/kouraiScene3.mp4'
import Scene4 from '../../assets/videos/kouraiScene4.mp4'

import Phone from '../../assets/svg/phoneOutline.svg'

const videos = [
    {
        title: 'Smart Perimeter',
        description:
            'Alerts only when it is needed most.',
        source: Scene1,
    },
    {
        title: 'Personalized',
        description:
            'Understands that different people require different levels of scrutiny.',
        source: Scene2,
    },
    {
        title: 'Interactivity',
        description:
            'Reassure everything is OK with a simple thumbs up.',
        source: Scene3,
    },
    {
        title: 'Always Aware',
        description:
            'Uninterrupted awareness of potential drowning danger, even in crowded situations.',
        source: Scene4,
    },
]

const VideoTabs = () => {
    const [value, setValue] = useState(0)

    return (
        <>
            {videos.map(({ source }, index) => (
                <div
                    key={index}
                    role="tabpanel"
                    hidden={value !== index}
                    id={`video-panel-${index}`}
                    aria-labelledby={`video-tab-${index}`}
                >
                    {value === index && (
                        <div className="phonePlayer">
                            <Phone className="phoneOutline" />
                            <video
                                className="tabVideo"
                                autoPlay
                                muted
                                playsInline
                                aria-hidden="true"
                                onEnded={() =>
                                    setValue(index + 1 === videos.length ? 0 : index + 1)
                                }
                            >
                                <source src={source} type="video/mp4" />
                            </video>
                        </div>
                    )}
                </div>
            ))}
            <Tabs
                value={value}
                scrollButtons={false}
                variant="fullWidth"
                aria-label="Kourai Video Tabs"
                onChange={(_, newValue) => setValue(newValue)}
                classes={{
                    root: 'videoTabs',
                    indicator: 'tabIndicator',
                    scroller: 'tabScroller',
                    flexContainer: 'tabFlexContainer',
                }}
            >
                {videos.map(({ title, description }, index) => (
                    <Tab
                        key={index}
                        disableRipple
                        classes={{ root: 'videoTabLabel', selected: 'tabSelected' }}
                        label={
                            <>
                                <hr className="tabHR"></hr>
                                <span className="tabTitle">{title}</span>
                                <span className="tabDescription">{description}</span>
                            </>
                        }
                        id={`video-tab-${index}`}
                        aria-controls={`video-panel-${index}`}
                    />
                ))}
            </Tabs>
        </>
    )
}

export default VideoTabs
