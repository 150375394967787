import React from 'react'
import { useTranslation } from 'react-i18next'
// import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Problem from '../components/Problem'
import System from '../components/System'
import About from '../components/About'
import ContactForm from '../components/ContactForm'
import SignUpButton from '../components/signUpButton/SignUpButton'
import FAQ from '../components/faq/faq'

// import Header from '../assets/svg/header.svg'
import SignUpBackground from '../assets/svg/signUpBackground.svg'
import VideoTabs from '../components/videoTabs/VideoTabs'

const IndexPage = () => {
    const { t } = useTranslation('homepage')
    return (
        <Layout>
            <SEO title="homepage:title" />
            {/* <div className="welcomeHeader">
                <Header className="welcomeHeaderBackground" />
                <div className="welcomeHeaderTitles">
                    <h1 className="homeTitle">
                        Stop drowning
                        <span className="homeSubtitle">with AI</span>
                    </h1>
                    <SignUpButton text="Join our waitlist" />
                </div>
                <StaticImage alt="Pool" className="poolImage" src="../assets/images/pool.png" />
            </div> */}
            <VideoTabs />
            <Problem />
            <System />
            <About />
            <div id="signUp" className="signUpPage">
                <SignUpBackground className="signUpBackground" />
                <h2 className="signUpDescription">{t('signUpDescription')}</h2>
                <SignUpButton text="Join our waitlist" />
            </div>
            <FAQ />
            <ContactForm />
        </Layout>
    )
}

export default IndexPage
