import React from 'react'
import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChipClasses from '../utils/chipProp'

const Problem = () => {
    const { t } = useTranslation('problem')

    return (
        <div className="problemPage" id="problem">
            <div>
                <Chip label={t('problem')} {...ChipClasses} />
                <h2 className="pageTitle">{t('header')}</h2>
                <p className="problemDescription">{t('description')}</p>
            </div>
            <div className="problemStats">
                <div className="problemStat">
                    <span className="statTitle">
                        <span className="smallerTitle">#</span>1
                    </span>
                    <p>{t('accidentalDeathStat')}</p>
                </div>
                <div className="problemStat">
                    <span className="statTitle">
                        11<span className="smallerTitle"> deaths</span>
                    </span>
                    <p>{t('drowningStat')}</p>
                </div>
                <div className="problemStat">
                    <span className="statTitle">
                        70<span className="smallerTitle">%</span>
                    </span>
                    <p>{t('childStat')}</p>
                </div>
            </div>
        </div>
    )
}

export default Problem
